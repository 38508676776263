import styles from './CenterAuthStructure.module.scss';
// import logoBrand from '../../../../assets/logo_example.png';
import { Link, Outlet } from 'react-router-dom';
import Copyright from '../../../app/components/Copyright';
import { useAppStoreSelector } from '../../../../store';
import { selectIsGuestMode } from '../../../portal/store';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CenterAuthStructure({
  imageUrl,
  logoBrand,
}: {
  imageUrl: string | null;
  logoBrand: string | null;
}) {
  const { t } = useTranslation();
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  return (
    <main
      className={`ps ${styles['wrapper']}`}
      style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}
    >
      <div className={styles['auth-wrapper']}>
        {logoBrand && <img src={logoBrand} />}
        <Outlet />
        {/* {children} */}
        <Copyright />
        {isGuest && (
          <Link to="/">
            <Button variant="outline-primary">
              {t('auth.visitGuestModeButton')}
            </Button>
          </Link>
        )}
      </div>
    </main>
  );
}

export default CenterAuthStructure;
