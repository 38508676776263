export function sortPortalsByPosition(
  a: { portalPositionOrder: number | null; portalName: string | null},
  b: { portalPositionOrder: number | null; portalName: string | null}
) {
  return (
    // @ts-ignore
    (b.portalPositionOrder != null) - (a.portalPositionOrder != null) ||
    // @ts-ignore
    a.portalPositionOrder - b.portalPositionOrder ||
    // @ts-ignore
    a.portalName.localeCompare(b.portalName)
  );
}

export function truncateString(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  } else return str;
}
