import { RouteObject } from 'react-router-dom';
import Admin from '../Admin';
import Users from '../components/Users';

const routes: RouteObject[] = [
  {
    path: 'admin',
    element: <Admin />,
    children: [
      {
        path: '',
        element: <Users />,
      },
      {
        path: 'user/:username',
        element: <p>user</p>,
      },
    ],
  },
];

export default routes;
