import { createSlice } from '@reduxjs/toolkit';

export const TOAST_SLICE_NAME = 'toast';

interface SliceDataState {
  data: {
    isShow: boolean;
    message?: string;
    isError: boolean;
  };
}

const initialState: SliceDataState = {
  data: {
    isShow: false,
    isError: false,
  },
};

export const toastSlice = createSlice({
  name: TOAST_SLICE_NAME,
  initialState,
  reducers: {
    triggerMessage: (state, action) => {
      const { payload } = action;
      const { message, isError } = payload;
      state.data.isShow = true;
      state.data.message = message;
      state.data.isError = isError;
    },
    cleanMessage: (state) => {
      state.data.isShow = false;
      state.data.message = undefined;
    },
  },
});

export const { triggerMessage, cleanMessage } = toastSlice.actions;

export default toastSlice.reducer;
