import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import styles from './AppBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIcon } from '../../../utils/iconFinder';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownButton } from 'react-bootstrap';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { logout } from '../../auth/store/userSlice';
import UserSettings from '../../auth/components/UserSettings';
import Drawer from '../components/Drawer';
import { useHideNavbar } from './AppBar.hook';
import axios from '../../../utils/api/axiosInstance';
import Loading from '../../general/components/Loading';
import {
  fetchGuestPortalsThunk,
  fetchPortalsThunk,
  removePortals,
  selectIsGuestMode,
  selectSelectedPortal,
} from '../../portal/store';
import { fetchSettingsThunk } from '../../settings/store';
import User from '../../auth/models/user';
import logo_not_found from '../../../assets/no_logo_found.png';
import { useToast } from '../../general/components/Toast.hook';
import { toggleDrawer } from '../store';
import { fetchCategoriesThunk } from '../../category/store';
import { AxiosError } from 'axios';
import ImageFallback from '../../general/components/ImageFallback';
// import Dropdown from './Dropdown';

export default function AppNavBar({
  setAppLoading,
}: {
  setAppLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isUpdateDatabaseLoading, setIsUpdateDatabaseLoading] = useState(false);
  const navBarRef = useRef<HTMLElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  // const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const dispatch = useAppStoreDispatch();
  const navigate = useNavigate();
  const userState = useAppStoreSelector((state) => state.user);
  const selectedPortal = useAppStoreSelector(selectSelectedPortal);
  // const portalState = useAppStoreSelector(state => state.portal)
  // const isHamburgerActive = useAppStoreSelector(
  //   (state) => state.app.data.modals.drawer.isDrawerOpen
  // );
  const postSettingsState = useAppStoreSelector((state) => state.settings.data);
  const location = useLocation();
  const token = User.loadUserToken();
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const { t } = useTranslation();
  const [triggerMessage] = useToast();
  const onLogoutClickHandler = () => {
    dispatch(logout());
    dispatch(removePortals());

    navigate('/auth');
  };
  useHideNavbar(navBarRef, drawerRef, postSettingsState?.sizes.navbar, () => {
    dispatch(toggleDrawer(false));
  });

  // useEffect(() => {
  //     console.log('Location: ', location, location.pathname.includes('/portal/'));
  // }, [location]);

  const onUpdateDatabaseHandler = async () => {
    try {
      setIsUpdateDatabaseLoading(true);
      setAppLoading(true);
      await axios.post('/api/admin/reconfigure', {});
      await dispatch(fetchPortalsThunk()).unwrap();
      await dispatch(fetchGuestPortalsThunk()).unwrap();
      await dispatch(fetchSettingsThunk()).unwrap();
      await dispatch(fetchCategoriesThunk()).unwrap();
    } catch (error) {
      console.log(error);
      if ((error as AxiosError).message) {
        triggerMessage((error as AxiosError).message, true);
      } else {
        triggerMessage(
          'Something went wrong with the update of the data!',
          true
        );
      }
    } finally {
      setIsUpdateDatabaseLoading(false);
      setAppLoading(false);
    }
  };


  const computeUpdateDatabaseElement = () => {
    //get role from userState
    if (userState.data.user?.isSuperuser || userState.data.user?.permissions?.includes('syncData')) {
      if (isUpdateDatabaseLoading) {
        return <Loading />;
      } else {
        return (
          <Dropdown.Item eventKey="3" as="p" onClick={onUpdateDatabaseHandler}>
            Update Data
          </Dropdown.Item>
        );
      }
    }
    return null;
  };

  const computeNavBarSelectedHeight = () => {
    // const heightNumber = postSettingsState?.sizes.navbar.heigth
    const heightNumber = postSettingsState?.sizes.navbar
      .match(/([\d\.]+)(.*)/)
      ?.filter((num) => +num)
      .join();
    // console.log(heightNumber);
    return heightNumber && +heightNumber >= 3.5
      ? `${+heightNumber}rem`
      : '3.5rem';
  };

  return (
    <>
      <UserSettings isModalShow={isModalShow} setIsModalShow={setIsModalShow} />
      <Navbar
        bg="light"
        variant="light"
        className={`ps ${styles['navbar']}`}
        style={{
          height: computeNavBarSelectedHeight(),
        }}
        ref={navBarRef}
      >
        <Drawer
          drawerRef={drawerRef}
          // isHamburgerActive={isHamburgerActive}
          // setIsHamburgerActive={setIsHamburgerActive}
          navBarHeight={computeNavBarSelectedHeight()}
        />
        {/* {postSettingsState?.logo && ( */}
        <Link
          to="/"
          style={{
            height: '100%',
            padding: '.3rem',
            textAlign: 'center',
            textDecoration: 'none',
            color: 'inherit',
          }}
          onClick={() => dispatch(toggleDrawer(false))}
        >
          <Navbar.Brand style={{ padding: 0, height: '100%', display: 'flex' }}>
            {/* <img */}
            {/*   src={postSettingsState.logo} */}
            {/*   style={{ height: '100%' }} */}
            {/*   alt="Portal Station Logo" */}
            {/*   onError={() => { */}
            {/*     console.log('Error'); */}
            {/*     return `this.onerror=null;this.src='${logo_not_found}'`; */}
            {/*   }} */}
            {/* /> */}
            {postSettingsState?.logo ? (
              <ImageFallback
                image={postSettingsState.logo}
                style={{ height: '100%' }}
                alt="Portal Station Logo"
              />
            ) : (
              <img
                src={logo_not_found}
                style={{ height: '40%' }}
                alt="not found"
              />
            )}
            {selectedPortal && (
              <div>{selectedPortal?.portalWidgetHeadline}</div>
            )}
          </Navbar.Brand>
        </Link>

        {/* {!location.pathname.includes('/portal/') && ( */}
        <DropdownButton
          className="ps"
          drop="down"
          align="end"
          variant="outline-primary"
          title={<FontAwesomeIcon icon={findIcon('far', 'user')} />}
          style={{
            visibility: location.pathname.includes('/portal/')
              ? 'hidden'
              : 'visible',
          }}
        >
          {!location.pathname.includes('/portal/') && (
            <>
              {computeUpdateDatabaseElement()}
              <Dropdown.Item eventKey="3" onClick={() => setIsModalShow(true)}>
                {t('app.settings')}
              </Dropdown.Item>

              <Dropdown.Divider className="ps" />

              {token && (
                <Dropdown.Item eventKey="2" onClick={onLogoutClickHandler}>
                  {t('app.logout')}
                </Dropdown.Item>
              )}
              {!token && isGuest && (
                <Dropdown.Item eventKey="1" onClick={() => navigate('/auth')}>
                  {t('auth.signIn')}
                </Dropdown.Item>
              )}
            </>
          )}
        </DropdownButton>
        {/* ) */}
        {/* } */}
      </Navbar>
      {/* {selectedPortal?.portalName && ( */}
      {/*   <div */}
      {/*     style={{ */}
      {/*       width: '100%', */}
      {/*       // backgroundColor: 'red', */}
      {/*       zIndex: 1, */}
      {/*       position: 'fixed', */}
      {/*       top: postSettingsState?.sizes.navbar, */}
      {/*       // marginTop: postSettingsState?.sizes.navbar, */}
      {/*     }} */}
      {/*     ref={titleRef} */}
      {/*   > */}
      {/*     <div */}
      {/*       style={{ */}
      {/*         width: '20rem', */}
      {/*         backgroundColor: 'black', */}
      {/*         border: 'solid black 1px', */}
      {/*         margin: '0 auto', */}
      {/*         clipPath: 'polygon(0 0, 100% 0, 100% 0, 90% 100%, 10% 100%, 0 0)', */}
      {/*       }} */}
      {/*     > */}
      {/*       <div */}
      {/*         style={{ */}
      {/*           width: 'calc(20rem - 2px)', */}
      {/*           backgroundColor: 'white', */}
      {/*           margin: '0 auto', */}
      {/*           clipPath: */}
      {/*             'polygon(0 0, 100% 0, calc(100% - 1px) 0, 90% 100%, 10% 100%, 1px 0)', */}
      {/*           textAlign: 'center', */}
      {/*         }} */}
      {/*       > */}
      {/*         {selectedPortal.portalName} */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
    </>
  );
}
