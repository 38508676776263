import { useEffect, useState } from 'react';
import { useAppStoreSelector } from '../../store';
import { sortPortalsByPosition } from '../../utils/functions';
import { Portals, selectIsGuestMode } from '../portal/store';

function usePortals() {
  const portalState = useAppStoreSelector((state) => state.portal);
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const [computedPortals, setComputedPortals] = useState<Portals[]>([]);
  const { activeCategory, portals, guestPortals } = portalState.data;
  const token = useAppStoreSelector((state) => state.user.data.token);

  useEffect(() => {
    console.log('Active Category: ', portalState.data.activeCategory);
    let newPortals: Portals[] = [];
    function computeAllPortals() {
      newPortals = [];
      if (isGuest) {
        console.log('Guest Portals: ', portalState.data.guestPortals);
        if (portalState.data.guestPortals) {
          newPortals.push(...portalState.data.guestPortals);
        }
      }
      if (portalState.data.portals) {
        newPortals.push(...portalState.data.portals);
      }

      newPortals = newPortals.filter(
        (value, index, self) =>
          index ===
          self.findIndex((element) => element.portalID === value.portalID)
      );

      newPortals.sort(sortPortalsByPosition);
      // newPortals.sort(function (a, b) {
      //   return (
      //     // @ts-ignore
      //     (b.portalPositionOrder != null) - (a.portalPositionOrder != null) ||
      //     a.portalPositionOrder - b.portalPositionOrder
      //   );
      // });
      // newPortals.sort((a, b) => a.portalPositionOrder - b.portalPositionOrder);
      // newPortals.sort((a, b) => {
      //   // @ts-ignore
      //   return (a === null) - (b === null) || +(a > b) || -(a < b);
      //   // if (a === null) {
      //   //   return 1;
      //   // }
      //   // if (b === null) {
      //   //   return -1;
      //   // }
      //   // return a.portalPositionOrder - b.portalPositionOrder;
      // });

      console.log('Active Category: ', newPortals);
      setComputedPortals([...newPortals]);
    }

    function computeGuestPortals() {
      newPortals = [];
      if (isGuest) {
        console.log('Guest Portals: ', portalState.data.guestPortals);
        if (portalState.data.guestPortals) {
          newPortals.push(...portalState.data.guestPortals);
        }
      }
      newPortals.sort(sortPortalsByPosition);
      setComputedPortals([...newPortals]);
    }

    function computeCategoryPortals() {
      newPortals = portalState.data.portals.filter((portal) =>
        portal.portalCategories?.includes(activeCategory)
      );

      newPortals.sort(sortPortalsByPosition);
      setComputedPortals([...newPortals]);
    }

    if (activeCategory === 'All') {
      computeAllPortals();
    } else if (activeCategory === 'Guest') {
      computeGuestPortals();
    } else {
      computeCategoryPortals();
    }
  }, [activeCategory, portals, token, portalState]);

  return [computedPortals];
}

export default usePortals;
