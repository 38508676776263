import { Button } from 'react-bootstrap';
import { useAppStoreSelector } from '../../store';
import styles from './Admin.module.scss';
import { Link, Outlet } from 'react-router-dom';

function Admin() {
  const adminState = useAppStoreSelector((state) => state.admin);
  return (
    <div className={styles['admin-container']}>
      <nav className={styles['admin-nav']}>
        {adminState.data.selectedUsers.map((selectedUser, index) => (
          <Link
            to={index === 0 ? '/admin' : `/admin/user/${selectedUser}`}
            key={index}
          >
            <Button variant="outline-primary">{selectedUser}</Button>
          </Link>
        ))}
      </nav>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default Admin;
