import { RouteObject } from 'react-router-dom';
import PortalRenderer from '../components/PortalRenderer';

const routes: RouteObject[] = [
  {
    path: 'portal/:portalId',
    element: <PortalRenderer />,
    loader: ({ params }) => params.portalId!,
  },
];

export default routes;
