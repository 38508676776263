import { Card } from 'react-bootstrap';
import Copyright from './Copyright';

function Footer() {
  return (
    <Card.Footer
      style={{
        marginTop: '1rem',
        padding: '.3rem',
      }}
    >
      <Copyright />
    </Card.Footer>
  );
}

export default Footer;
