import Dashboard from '../Dashboard';
import { RouteObject } from 'react-router-dom';
import Launcher from 'toolkit-template';

const routes: RouteObject[] = [
  {
    path: '',
    element: <Dashboard />,
  },
  // {
  //   path: '/test',
  //   element: <Launcher selectedFunction="FunctionTestNoRequest" />,
  // },
];

export default routes;
