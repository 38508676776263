import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { fetchGuestPortals, fetchPortals } from '../api/portalAPI';
import { RootState } from '../../../store/index';

export const fetchPortalsThunk = createAsyncThunk(
  'portal/fetchPortals',
  async (_thunkAPI) => {
    console.log('Dashboard token fetch');
    try {
      const response = await fetchPortals();
      console.log('Portals: ', response);
      return response;
    } catch (error) {
      console.log('Error: ', error);
      throw new Error((error as AxiosError).response?.statusText);
    }
  }
);

export const fetchGuestPortalsThunk = createAsyncThunk(
  'guest/portal',
  async (_thunkAPI) => {
    try {
      const response = await fetchGuestPortals();
      return response;
    } catch (error) {
      console.log('Error: ', error);
      throw new Error((error as AxiosError).response?.statusText);
    }
  }
);

export const PORTAL_SLICE_NAME = 'portal';

export type Portals = {
  portalID: number;
  portalName: string;
  portalWidgetHeadline: string;
  portalWidgetImageType: string;
  portalWidgetImageValue: string;
  portalWidgetText: string;
  portalPositionOrder: number;
  portalClass: string;
  importModalStoredState?: any;
  portalCategories: [string] | null;
};

interface SliceDataState {
  data: {
    guestPortals?: Portals[];
    portals: Portals[];
    activePortals: number[];
    activeCategory: string;
    selectedPortalId: number | null;
    // languageEvent: BehaviorSubject<string>;
  };
}

const initialState: SliceDataState = {
  data: {
    portals: [],
    activePortals: localStorage.getItem('activePortals')
      ? JSON.parse(localStorage.getItem('activePortals')!)
      : [],
    guestPortals: undefined,
    activeCategory: 'All',
    selectedPortalId: null,
    // languageEvent: new BehaviorSubject('en'),
  },
};

export const portalSlice = createSlice({
  name: PORTAL_SLICE_NAME,
  initialState,
  reducers: {
    addGuestPortals(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          guestPortals: action.payload.results as Portals[],
        },
      };
    },
    addPortals(state, action) {
      // console.log('Images: ', action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          portals: action.payload.results as Portals[],
        },
      };
    },
    removePortals(state) {
      return {
        ...state,
        data: {
          ...state.data,
          portals: [],
          activePortals: [],
        },
      };
    },
    storeImportModalData(state, action) {
      const { portalId, data } = action.payload;
      state.data.portals.forEach((portal) => {
        if (portal.portalID === portalId) {
          portal.importModalStoredState = data;
        }
      });
    },
    addActivePortal(state, action) {
      const { id } = action.payload as { id: number };
      const updatedIds = [...state.data.activePortals] as number[];
      updatedIds.unshift(id);
      localStorage.setItem(
        'activePortals',
        JSON.stringify([...new Set(updatedIds)])
      );
      return {
        ...state,
        data: {
          ...state.data,
          activePortals: [...new Set(updatedIds)],
          selectedPortalId: id,
        },
      };
    },
    removeActivePortal(state, action) {
      const updatedIds = [...state.data.activePortals];
      const { id } = action.payload as { id: number };
      const foundId = state.data.activePortals.findIndex(
        (portal) => portal === id
      );
      if (foundId >= 0) {
        updatedIds.splice(foundId, 1);
      }
      localStorage.setItem('activePortals', JSON.stringify(updatedIds));
      return {
        ...state,
        data: {
          ...state.data,
          activePortals: updatedIds,
        },
      };
    },
    changeActiveCategory(state, action) {
      const { newActiveCategory } = action.payload as {
        newActiveCategory: string;
      };
      return {
        ...state,
        data: {
          ...state.data,
          activeCategory: newActiveCategory,
        },
      };
    },
    removeSelectedPortal(state) {
      return {
        ...state,
        data: {
          ...state.data,
          selectedPortalId: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPortalsThunk.fulfilled,
      portalSlice.caseReducers.addPortals
    );

    builder.addCase(
      fetchGuestPortalsThunk.fulfilled,
      portalSlice.caseReducers.addGuestPortals
    );
  },
});

export const {
  storeImportModalData,
  addActivePortal,
  removeActivePortal,
  removePortals,
  changeActiveCategory,
  removeSelectedPortal,
} = portalSlice.actions;

export const selectIsGuestMode = createSelector(
  (state: RootState) => state.portal.data.guestPortals,
  (guestPortals) => guestPortals && guestPortals?.length > 0
);

export const selectSelectedPortal = createSelector(
  (state: RootState) => state.portal.data,
  (portalData) =>
    portalData &&
    portalData.portals.find(
      (portal) => portal.portalID === portalData.selectedPortalId
    )
);

export default portalSlice.reducer;
