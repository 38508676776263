import axios, { AxiosHeaders } from 'axios';
import User from '../../features/auth/models/user';

let baseURL = window.location.origin;

const instance = axios.create({
  baseURL,
  // timeout: 1000 * 5,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
instance.interceptors.request.use(function (config) {
  const token = User.loadUserToken();
  if (config.headers) {
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
    // config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// export function attachTokenInterceptor(token: string) {
//   // console.log('Token attach: ', token);
//   instance.interceptors.request.use(function (config) {
//     if (config.headers) {
//       (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
//       // config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   });
// }

export default instance;
