import axios from '../../../utils/api/axiosInstance';

export async function fetchPortalBasedOnId(id: number, isGuest: boolean) {
  const url = isGuest
    ? `/api/guest/portal/${id}/settings`
    : `/api/portal/${id}/settings`;
  try {
    const response = await axios.get(url);
    return JSON.parse(response.data.results);
  } catch (error) {
    const errorObj = error as any;
    const message = errorObj.response.data.message as string;
    console.log('Axios: ', error);
    throw new Error(message);
  }
}

export async function fetchPortalStationConfig() {
  try {
    const response = await axios.get(`/api/settings/`);
    return response.data.results;
  } catch (error) {
    // const errorObj = error as any;
    // const message = errorObj.response.data.message as string;
    console.log(error);
    // throw new Error(message);
  }
}
