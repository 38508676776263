import { createSlice } from '@reduxjs/toolkit';

export const APP_SLICE_NAME = 'app';

interface SliceDataState {
  data: {
    modals: {
      drawer: {
        isDrawerOpen: boolean;
      };
    };
  };
}

const initialState: SliceDataState = {
  data: {
    modals: {
      drawer: {
        isDrawerOpen: false,
      },
    },
  },
};

export const appSlice = createSlice({
  name: APP_SLICE_NAME,
  initialState,
  reducers: {
    // openDrawer(state) {
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       modals: {
    //         ...state.data.modals,
    //         drawer: {
    //           ...state.data.modals.drawer,
    //           isDrawerOpen: true,
    //         },
    //       },
    //     },
    //   };
    // },
    // closeDrawer(state) {
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       modals: {
    //         ...state.data.modals,
    //         drawer: {
    //           ...state.data.modals.drawer,
    //           isDrawerOpen: false,
    //         },
    //       },
    //     },
    //   };
    // },
    toggleDrawer(state, action) {
      const drawerOption = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            drawer: {
              ...state.data.modals.drawer,
              isDrawerOpen:
                drawerOption !== null
                  ? drawerOption
                  : !state.data.modals.drawer.isDrawerOpen,
            },
          },
        },
      };
    },
  },
});

export const { toggleDrawer } = appSlice.actions;

export default appSlice.reducer;
