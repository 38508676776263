import { useState } from 'react';
import logo_not_found from '../../../assets/no_logo_found.png';

export default function ImageFallback({
  image,
  alt,
  style,
}: {
  image: string;
  alt: string;
  style: any;
}) {
  const [currentImage, setCurrentImage] = useState(image);
  const [currentStyles, setCurrentStyles] = useState({ ...style });
  return (
    <img
      src={currentImage}
      style={{ ...currentStyles }}
      alt={alt}
      onError={() => {
        setCurrentImage(logo_not_found);
        setCurrentStyles({ width: '10rem' });
      }}
    />
  );
}
