import { useAppStoreDispatch } from '../../../store';
import { toggleDrawer } from '../../app/store';
import styles from './HamburgerIcon.module.scss';

function HamburgerIcon({
  isActive,
  // setIsActive,
  navBarHeight,
}: {
  isActive: boolean;
  // setIsActive: (prevState: any) => any;
  navBarHeight: string;
}) {
  const dispatch = useAppStoreDispatch();
  const onHamburgerClickHandler = () => {
    dispatch(toggleDrawer(null));
    // setIsActive((prevState: boolean) => !prevState);
  };
  return (
    <button
      className={`ps ${styles['hamburger']} ${
        styles['hamburger--collapse']
      } nav-link active py-3 rounded-0 ${
        isActive ? styles['is-active'] : ''
      } color-primary`}
      style={{
        width: '100%',
        display: 'flex',
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0px 0px 0px 4px',
        overflow: 'hidden',
        height: navBarHeight,
      }}
      type="button"
      onClick={onHamburgerClickHandler}
    >
      <span
        className={`${styles['hamburger-box']}`}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <span
          className={`${styles['hamburger-inner']}`}
          style={{ width: '1.875rem', backgroundColor: 'var(--bs-primary)' }}
        ></span>
      </span>
    </button>
  );
}

export default HamburgerIcon;
