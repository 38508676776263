import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { useAppStoreSelector } from '../../../../store';
import { findIcon } from '../../../../utils/iconFinder';
import Copyright from '../../../app/components/Copyright';
import { selectIsGuestMode } from '../../../portal/store';
import styles from '../../Auth.module.scss';
import { useTranslation } from 'react-i18next';

function DoubleSideAuthStructure({
  isRight = true,
  image,
}: {
  isRight?: boolean;
  image: string | null;
}) {
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const { t } = useTranslation();

  return (
    <main className="ps">
      <Row
        style={{
          height: '100vh',
          flexDirection: isRight ? 'row' : 'row-reverse',
        }}
      >
        {image ? (
          <Col
            sm={4}
            md={7}
            className={styles.image}
            style={{ padding: '0 !important' }}
          >
            <img
              src={image}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        ) : (
          <Col sm={4} md={7} className={styles.image} />
        )}

        <Col
          xs={12}
          sm={8}
          md={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={styles.paper}>
            <div className={styles['lock-badge']}>
              <FontAwesomeIcon icon={findIcon('fas', 'lock')} />
            </div>
            <Outlet />
            <div>
              <Copyright />
            </div>
            <div>
              {isGuest && (
                <Link to="/">
                  <Button variant="outline-primary">
                    {t('auth.visitGuestModeButton')}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
}

export default DoubleSideAuthStructure;
