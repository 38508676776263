import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useAppStoreSelector } from '../../store';

import common_de from './de.json';
import common_en from './en.json';

/*

{
  "appname": "Example",
  "welcome": "Welcome to $t(appname)", // nesting
  "key": "item",
  "key_plural": "items", // plurals, a var has to be provided like t('key', { count: x })
  "keyWithCount": "{{count}} item", // those vars are accessible in the translation
  "keyWithCount_plural": "{{count}} items"
}

For more look at https://www.i18next.com/ and https://react.i18next.com/.

*/

if (process) {
  i18n.use(LanguageDetector).init({
    debug: process.env.NODE_ENV !== 'production',
    // prevents XSS, but React already does escaping
    interpolation: { escapeValue: false },
    // if fallback language is not defined the library can create a json with missing translations
    fallbackLng: process.env.NODE_ENV !== 'production' ? ['en', 'de'] : '',
    // ignore locals 'en-US' -> 'en'
    load: 'languageOnly',
    // all translations
    resources: {
      en: {
        common: common_en,
      },
      de: {
        common: common_de,
      },
    },
    defaultNS: 'common',
    // alternatively those can be served by a server. Use package i18next-xhr-backend for that and set options here
    // backend: { }
  });
}

export default function TranslationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const portalState = useAppStoreSelector((state) => state.portal);
  // const { t, i18n } = useTranslation();
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export function mapToBCP47Import(lng: string) {
  return {
    en: 'enUS',
    de: 'deDE',
  }[lng];
}
