import { redirect, RouteObject } from 'react-router-dom';
import Auth from '../Auth';
import Login from '../components/Login';
// import Register from '../components/Register';
// import User from '../models/user';

function authRoutes() {
  return [
    {
      path: '/auth',
      element: <Auth />,
      // loader: () => {
      //   const token = User.loadUserToken();
      //   if (token) {
      //     return redirect('/');
      //   }
      //   return null;
      // },
      children: [
        {
          path: '',
          element: <Login />,
        },
        // {
        //   path: 'register',
        //   element: <Register />,
        // },
      ],
    },
  ] as RouteObject[];
}

export default authRoutes;
