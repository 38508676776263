import React, { useRef, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import DrawerItem from './DrawerItem';
import styles from './Drawer.module.scss';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import HamburgerIcon from '../../general/components/HamburgerIcon';
import { useTransition, animated, config } from '@react-spring/web';
import usePortals from '../../dashboard/Dashboard.hook';
import { toggleDrawer } from '../store';

function DrawerEl({
  drawerRef,
  // isHamburgerActive,
  // setIsHamburgerActive,
  navBarHeight,
}: {
  drawerRef: React.RefObject<HTMLDivElement>;
  // isHamburgerActive: boolean;
  // setIsHamburgerActive: (
  //   isActive: boolean | ((prevState: boolean) => boolean)
  // ) => void;
  navBarHeight: string;
}) {
  const [isIconHovering, setIsIconHovering] = useState(false);
  const portalState = useAppStoreSelector((state) => state.portal);
  const postSettings = useAppStoreSelector((state) => state.settings);
  const dispatch = useAppStoreDispatch();
  const navigate = useNavigate();
  const isHamburgerActive = useAppStoreSelector(
    (state) => state.app.data.modals.drawer.isDrawerOpen
  );
  const drawerWidth = postSettings.data?.sizes.drawer;

  const onMouseOverHandler = () => {
    setIsIconHovering(true);
  };
  const onMouseOutHandler = () => {
    setIsIconHovering(false);
  };

  const onPortalItemClickHandler = () => {
    dispatch(toggleDrawer(false));
    // setIsHamburgerActive(false);
  };

  const [portals] = usePortals();

  const navTransition = useTransition(isHamburgerActive, {
    from: { opacity: 0, height: '0' },
    enter: { opacity: 1, height: '100%' },
    leave: { opacity: 0, height: '0' },
    config: config.gentle,
    // exitBeforeEnter: true,
    // onRest: () => setIsHamburgerActive((prevState: boolean) => !prevState),
    // reverse: isHamburgerActive,
  });

  return (
    <>
      {/* <UserSettings isModalShow={isModalShow} setIsModalShow={setIsModalShow} /> */}
      <Nav style={{ position: 'relative', zIndex: 900 }} className="ps">
        <div
          className={`d-flex flex-column flex-shrink-0 ${styles['nav-bar']}`}
          ref={drawerRef}
          style={{
            height: isHamburgerActive ? '100%' : navBarHeight,
            width: drawerWidth ? drawerWidth : '3.5rem',
          }}
        >
          <div
            onMouseOut={onMouseOutHandler}
            onMouseOver={onMouseOverHandler}
            style={{ borderRadius: '0 0 0 4px', height: navBarHeight }}
          >
            {/* <OverlayTrigger placement="right" overlay={<Tooltip>dashboard</Tooltip>}> */}
            <Nav.Item
              // className="nav nav-pills nav-flush text-center"
              style={{
                width: '100%',
                overflow: 'hidden',
                borderRadius: '0px 0px 0px 4px',
                height: '100%',
              }}
            >
              {/* {!isIconHovering && !isHamburgerActive ? ( */}
              {/* <Link */}
              {/*   to="/" */}
              {/*   className="nav-link active py-3 rounded-0 text-light" */}
              {/*   style={{ */}
              {/*     width: '100%', */}
              {/*     overflow: 'hidden', */}
              {/*     display: 'flex', */}
              {/*     alignItems: 'center', */}
              {/*     justifyContent: 'center', */}
              {/*   }} */}
              {/* > */}
              {/*   PS */}
              {/* </Link> */}
              {/* ) : ( */}
              <HamburgerIcon
                isActive={isHamburgerActive}
                // setIsActive={setIsHamburgerActive}
                navBarHeight={navBarHeight}
              />
              {/* )} */}
            </Nav.Item>
          </div>
          {navTransition(
            (propStyles, isShow) =>
              isShow && (
                <animated.div
                  style={propStyles}
                  className={`${styles['drawer-content']} bg-light bg-gradient`}
                >
                  <Nav.Item
                    as="ul"
                    className="nav nav-pills nav-flush flex-column mb-auto text-center"
                    style={{ margin: '0' }}
                  >
                    {portals.map((portal, index) => (
                      <DrawerItem
                        key={`${index}-${portal.portalName}`}
                        portalName={portal.portalName}
                        portalLink={`/portal/${portal.portalID}`}
                        setPortalDrawer={onPortalItemClickHandler}
                        portalWidgetImage={portal.portalWidgetImageValue}
                      />
                    ))}
                  </Nav.Item>
                </animated.div>
              )
          )}
        </div>
      </Nav>
    </>
  );
}

export default DrawerEl;
