import Modal from '../../general/components/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { languageEvent } from '../../../events/languageEvent';
import { ChangeEvent } from 'react';
import { toggleNewPageSwitch } from '../../settings/store';

interface UserSettingsProps {
  isModalShow: boolean;
  setIsModalShow: (show: boolean) => void;
}

const UserSettings = ({ isModalShow, setIsModalShow }: UserSettingsProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppStoreDispatch();
  const settingsState = useAppStoreSelector((state) => state.settings.data);
  // const portalState = useAppStoreSelector((state) => state.portal);

  const onLanguageSelectChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    window.dispatchEvent(languageEvent(selectedLanguage));
    // portalState.data.languageEvent.next(selectedLanguage);
  };

  const onNewPageSwitchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.checked);
    dispatch(toggleNewPageSwitch(event.target.checked));
  };

  return (
    <Modal
      show={isModalShow}
      onHide={() => setIsModalShow(false)}
      title={t('settings.userSettings')}
      footer={null}
    >
      <Form.Group className="ps">
        <Form.Label>{t('settings.language')}</Form.Label>
        <Form.Select
          onChange={onLanguageSelectChangeHandler}
          value={i18n.language}
        >
          <option value="en">EN</option>
          <option value="de">DE</option>
        </Form.Select>
        {/* <div style={{ width: '100%' }}> */}
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Open portals in new page"
          style={{ margin: '.5rem .3rem' }}
          onChange={onNewPageSwitchHandler}
          checked={settingsState?.isNewPageChecked}
          // disabled
        />
        {/* </div> */}
      </Form.Group>
    </Modal>
  );
};

export default UserSettings;
