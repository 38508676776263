import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIcon } from '../../../utils/iconFinder';

function NotFound404() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <FontAwesomeIcon icon={findIcon('fas', 'question')} size="6x" />
      <h1>404</h1>
      <h3>Sorry but this URL is not a valid resource</h3>
    </div>
  );
}

export default NotFound404;
