import Modal from 'react-bootstrap/Modal';

interface ModalProps {
    children: React.ReactNode;
    title: string;
    footer: React.ReactNode;
    show: boolean;
    onHide: () => void;
}

const ModalEL: React.FC<ModalProps> = ({children, title, footer, show, onHide}) => {
    return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                {children}
            </Modal.Body>
                <Modal.Footer>
                {footer}
            </Modal.Footer>
        </Modal>

    )
}

export default ModalEL;
