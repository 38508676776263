import axios from '../../../utils/api/axiosInstance';
import { Portals } from '../store';

export async function fetchPortals() {
  try {
    console.log('Dashboard axios: ', axios.request);
    const response = await axios.get<Portals[]>('/api/user/portals');
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchGuestPortals() {
  try {
    const response = await axios.get<Portals[]>('/api/guest/portals');
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
