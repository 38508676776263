import { useEffect } from 'react';

export function useHideNavbar(
  navBarRef: React.RefObject<HTMLElement | HTMLDivElement>,
  drawerRef: React.RefObject<HTMLDivElement>,
  height?: string,
  callback?: () => void
) {
  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (navBarRef.current && drawerRef.current) {
        if (prevScrollpos > currentScrollPos) {
          // document.getElementById("navbar").style.top = "0";
          navBarRef.current.style.top = '0';
          drawerRef.current.style.top = '0';
          // titleRef.current.style.top = height ? height : '-60px';
        } else {
          // document.getElementById("navbar").style.top = "-50px";
          navBarRef.current.style.top = height ? '-' + height : '-60px';
          drawerRef.current.style.top = height ? '-' + height : '-60px';
          // titleRef.current.style.top = '0';
          if (callback) {
            callback();
          }
        }
        prevScrollpos = currentScrollPos;
      }
    };
  }, []);
}
