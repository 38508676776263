import { Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppStoreDispatch } from '../../../store';
import { toggleDrawer } from '../../app/store';
import { addActivePortal, removeActivePortal } from '../../portal/store';
import styles from './ButtonWithClose.module.scss';

interface ButtonWithCloseProps {
  children: React.ReactNode;
  portalId: number;
}

function ButtonWithClose({ children, portalId }: ButtonWithCloseProps) {
  const dispatch = useAppStoreDispatch();
  const navigate = useNavigate();
  return (
    <Button
      variant="outline-dark"
      size="sm"
      className={`ps ${styles['button-wrapper']}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(toggleDrawer(false));
        navigate(`/portal/${portalId}`);
      }}
    >
      {children}
      <Badge
        pill
        bg="dark"
        as="span"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log('test');
          dispatch(removeActivePortal({ id: portalId }));
        }}
      >
        X
      </Badge>
    </Button>
  );
}

export default ButtonWithClose;
