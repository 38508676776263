import Spinner from 'react-bootstrap/Spinner';

const Loading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
    }}
  >
    <Spinner animation="grow" variant="primary" />
  </div>
);

export default Loading;
