import { useAppStoreSelector } from '../../../store';
import ButtonWithClose from '../../general/components/ButtonWithClose';
import styles from './LastVisited.module.scss';

function LastVisited() {
  const activePortals = useAppStoreSelector(
    (state) => state.portal.data.activePortals
  );
  const portals = useAppStoreSelector((state) => state.portal.data.portals);
  const guestPortals = useAppStoreSelector(
    (state) => state.portal.data.guestPortals
  );

  //Cap last visited portals to 5
  const lastVisitedPortals = activePortals.slice(-5);

  return (
    <div className={`ps ${styles['last-visited-wrapper']}`}>
      {lastVisitedPortals.map((portal) => {
        let selectedPortal = portals.find((port) => port.portalID === portal);
        if (!selectedPortal && guestPortals) {
          selectedPortal = guestPortals.find(
            (port) => port.portalID === portal
          );
        }
        // console.log('Active Portals: ', selectedPortal, portal);

        return (
          selectedPortal && (
            <ButtonWithClose
              portalId={selectedPortal.portalID}
              key={selectedPortal.portalID}
            >
              {selectedPortal.portalName.length > 15
                ? selectedPortal.portalName.substring(0, 12) + '...'
                : selectedPortal.portalName}
            </ButtonWithClose>
          )
        );
      })}
    </div>
  );
}
export default LastVisited;
