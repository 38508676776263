import React, { useEffect } from 'react';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store/index';
import { useTranslation } from 'react-i18next';
import FormEL, { Fields } from '../../general/components/Form';
import { addUserData, loginThunk } from '../store/userSlice';
// import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useToast } from '../../general/components/Toast.hook';
import * as yup from 'yup';
import RememberMe from '../../general/components/RemembemMe';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useCookie } from '../../../hooks/useCookie';
import User from '../models/user';
// import { attachTokenInterceptor } from '../../../utils/api/axiosInstance';

export default function LoginForm() {
  const fields: Fields[] = [
    {
      id: 'email',
      label: 'auth.usernameOrEmail',
      type: 'text',
      validator: yup.string().min(1).required(),
    },
    {
      id: 'password',
      label: 'auth.password',
      type: 'password',
      validator: yup.string().min(1).required(),
    },
  ];

  const [rememberMe, setRememberMe] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const config = useAppStoreSelector((state) => state.config);
  const [triggerMessage] = useToast();
  const navigate = useNavigate();
  const [token, eraseTokenCookie] = useCookie('token');
  const [expirationDate, eraseExpirationDateCookie] = useCookie('expires');
  const dispatch = useAppStoreDispatch();
  const appSettings = useAppStoreSelector((state) => state.settings);

  // useEffect(() => {
  //   const publicKey = config.data?.authConfig.jwtPublicKey;
  //   if (token && expirationDate && publicKey) {
  //     dispatch(addUserData({ token, publicKey }));
  //     if (rememberMe) {
  //       User.persistUser(token);
  //       // navigate('/');
  //     }
  //     eraseTokenCookie();
  //     eraseExpirationDateCookie();
  //     console.log('Token attach2: ', token);
  //     attachTokenInterceptor(token);
  //   }
  // }, [token]);

  const handleLogin = async (credentials: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      await dispatch(loginThunk({ credentials, rememberMe })).unwrap();
      navigate('/');
    } catch (error) {
      console.log(error);
      console.log(error instanceof AxiosError);
      const errorType = error as AxiosError;
      const errorMessage = errorType.message;
      triggerMessage(errorMessage, true);
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  const onRememberMeCheckChangeHandler = () => {
    setRememberMe((prevValue) => !prevValue);
  };

  const oidc = config.data?.authConfig.methods.oidc;
  const local = config.data?.authConfig.methods.local;

  const hasLogin =
    // appSettings.data?.generalSetup.loginSetup[0].authFunctions.includes(
    appSettings.data?.loginSetup.authFunctions.includes('login');
  const hasKeyclock =
    // appSettings.data?.generalSetup.loginSetup[0].authFunctions.includes(
    appSettings.data?.loginSetup.authFunctions.includes('keyclock');
  const hasForgotPassword =
    // appSettings.data?.generalSetup.loginSetup[0].authFunctions.includes(
    appSettings.data?.loginSetup.authFunctions.includes('forgotPassword');
  const hasRegister =
    // appSettings.data?.generalSetup.loginSetup[0].authFunctions.includes(
    appSettings.data?.loginSetup.authFunctions.includes('register');

  console.log('Register: ', hasRegister);
  console.log('Keyclock: ', hasKeyclock);
  console.log('Forgot Password: ', hasForgotPassword);
  console.log('Login: ', hasLogin);

  return (
    <>
      <h5>{t('auth.signIn')}</h5>
      {oidc && hasKeyclock && (
        <Button variant="light" href={oidc.URL} style={{ width: '90%' }}>
          {t('auth.oidcLogin', { oidcName: oidc.name })}
        </Button>
      )}
      {oidc && local?.loginEnabled && hasLogin && hasKeyclock && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <hr style={{ width: '90%', marginRight: '10px' }} />
          <p className="text-secondary" style={{ marginBottom: 0 }}>
            {t('auth.dividerOr')}
          </p>
          <hr style={{ width: '90%', marginLeft: '10px' }} />
        </div>
      )}
      {config.data?.authConfig.methods.local.loginEnabled && hasLogin && (
        <FormEL
          fields={fields}
          submitButtonTextKey="auth.signIn"
          loading={loading}
          onSubmit={handleLogin}
        >
          <RememberMe
            rememberMe={rememberMe}
            onRememberMeCheckChangeHandler={onRememberMeCheckChangeHandler}
          />
        </FormEL>
      )}
      {/* {config.data?.authConfig.methods.local.loginEnabled && */}
      {/*   config.data?.authConfig.methods.local.registerPath && */}
      {/*   hasRegister && ( */}
      {/*     <Row> */}
      {/*       <Col item> */}
      {/*         <Link to="register">{t('auth.signUpRef')}</Link> */}
      {/*       </Col> */}
      {/*     </Row> */}
      {/*   )} */}
    </>
  );
}
