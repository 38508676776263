import { configureStore } from '@reduxjs/toolkit';
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import adminReducer, { ADMIN_SLICE_NAME } from '../features/admin/store';
import appReducer, { APP_SLICE_NAME } from '../features/app/store';
import userReducer, { USER_SLICE_NAME } from '../features/auth/store/userSlice';
import categoryReducer, {
  CATEGORY_SLICE_NAME,
} from '../features/category/store';
import configReducer, { CONFIG_SLICE_NAME } from '../features/config/store';
import toastReducer, {
  TOAST_SLICE_NAME,
} from '../features/general/store/toast';
import portalReducer, { PORTAL_SLICE_NAME } from '../features/portal/store';
import settingsReducer, {
  SETTINGS_SLICE_NAME,
} from '../features/settings/store';

export const store = configureStore({
  reducer: {
    [USER_SLICE_NAME]: userReducer,
    [CONFIG_SLICE_NAME]: configReducer,
    [PORTAL_SLICE_NAME]: portalReducer,
    [TOAST_SLICE_NAME]: toastReducer,
    [ADMIN_SLICE_NAME]: adminReducer,
    [SETTINGS_SLICE_NAME]: settingsReducer,
    [CATEGORY_SLICE_NAME]: categoryReducer,
    [APP_SLICE_NAME]: appReducer,
  },
});

interface StoreProviderProps {
  children: React.ReactNode;
}

const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppStoreSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStoreDispatch: () => AppDispatch = useDispatch;

export default StoreProvider;
