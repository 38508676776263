import { createSlice } from '@reduxjs/toolkit';

export const ADMIN_SLICE_NAME = 'admin';

interface SliceDataState {
  data: {
    selectedUsers: string[];
  };
}

const initialState: SliceDataState = {
  data: {
    selectedUsers: ['dashboard'],
  },
};

export const adminSlice = createSlice({
  name: ADMIN_SLICE_NAME,
  initialState,
  reducers: {
    addSelectedUser(state, action) {
      const prevState = [...state.data.selectedUsers];
      prevState.push(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          selectedUsers: [...new Set(prevState)],
        },
      };
    },
  },
});

export const { addSelectedUser } = adminSlice.actions;

export default adminSlice.reducer;
