import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { useAppStoreSelector } from '../store';
import { selectIsGuestMode } from '../features/portal/store';
import authRoutes from '../features/auth/routes';
import appRoutes from '../features/app/routes';
import NotFound404 from '../features/general/components/NotFound404';

export default function AppContent() {
  const routes: RouteObject[] = [
    ...authRoutes(),
    ...appRoutes(),
    {
      path: '/*',
      element: <NotFound404 />,
    },
  ];
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}
