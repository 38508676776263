import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export interface Fields {
  id: string;
  label: string;
  // required?: boolean;
  // autoComplete?: string;
  // autoFocus?: boolean;
  type?: 'text' | 'password' | 'tel' | 'number' | 'email';
  // default?: string;
  validator: any;
}

interface FormProps {
  fields: Fields[];
  children: React.ReactNode;
  loading: boolean;
  submitButtonTextKey: string;
  onSubmit: (data: any) => void;
}

const FormEL: React.FC<FormProps> = ({
  fields,
  children,
  loading,
  submitButtonTextKey,
  onSubmit,
}) => {
  const validators: { [key: string]: any } = {};

  fields.forEach((field) => {
    console.log(field);
    validators[field.id] = field.validator;
  });

  const schema = yup.object(validators).required();

  const { handleSubmit, formState, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    console.log('Form State: ', formState);
  }, [formState]);

  const onSubmitHandler = (data: any) => {
    console.log(data);
    // console.log(errors);
    console.log(formState);
    console.log(schema);
    onSubmit(data);
  };

  const { t } = useTranslation();
  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmitHandler)}
      style={{ width: '90%', margin: '0 auto' }}
    >
      <p>{formState.isValid}</p>
      {fields.map(({ label, type, id }, index) => (
        <Form.Group as={InputGroup} key={index} controlId={index.toString()}>
          <FloatingLabel label={t(label)} className="mb-3">
            {/* <InputGroup.Text>{t(label)}</InputGroup.Text> */}
            <Controller
              control={control}
              name={id}
              defaultValue=""
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  aria-label={t(label)}
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  isInvalid={!!formState.errors[id]}
                  placeholder={`Insert ${id}`}
                  type={type}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {formState.errors[id]?.message as string}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      ))}
      {children}
      <div className="d-grid gap-2">
        <Button
          variant="primary"
          type="submit"
          disabled={loading || !formState.isValid}
        >
          {loading ? 'Loading...' : t(submitButtonTextKey)}
        </Button>
      </div>
    </Form>
  );
};

export default FormEL;
