import { useEffect } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useAppStoreSelector } from '../../store';
import { selectIsGuestMode } from '../portal/store';
import User from './models/user';

export function useAuthGuard() {
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const token = User.loadUserToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tokenState = useAppStoreSelector((state) => state.user.data.token);

  useEffect(() => {
    console.log('Dashboard: ', isGuest, !!!token, isGuest || !!!token);
    const path = pathname.split('/').filter((path) => path !== '')[0];

    // === DASHBOARD ===
    if (path === undefined) {
      if (!isGuest && !!!token) navigate('/auth');
    }

    // === AUTH ===
    if (path === 'auth') {
      if (token) navigate('/');
    }
  }, []);
}
