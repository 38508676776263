import frontendPackageData from '../../../../package.json';
// import backendPackageData from '../../../../package.json';
import { useAppStoreSelector } from '../../../store';
// TODO: Add a module declaration for the constants
// @ts-ignore
import { portal_version } from 'portal/dist/utils/constants';

function Copyright() {
  const settings = useAppStoreSelector((state) => state.settings);
  return (
    // <div className="text-secondary">
    <div
      style={{
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      {/* Frontend version: {frontendPackageData.version} Backend version:{' '}
            {settings.data?.backendVersion} */}
      ADIB Portal {portal_version} | {'Copyright © '}
      <a href="https://www.conigon.com/">CONIGON GmbH</a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </div>
  );
}

export default Copyright;
