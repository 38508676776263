import { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { changeActiveCategory, selectIsGuestMode } from '../../portal/store';
import styles from './PortalGroupNav.module.scss';
import { useTranslation } from 'react-i18next';


function PortalGroupNav() {
  const { t } = useTranslation();
  const [groups, setGroups] = useState(['All']);
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const portalState = useAppStoreSelector((state) => state.portal);
  const categoryState = useAppStoreSelector((state) => state.category);
  const dispatch = useAppStoreDispatch();
  const categories = categoryState.data.categories;
  useEffect(() => {
    const categoriesList = ['All'];
    if (categories.length > 0) {
      categoriesList.push(...categoryState.data.categories);
    }
    if (isGuest) {
      categoriesList.push('Guest');
    }
    setGroups([...categoriesList]);
  }, [categories]);

  const onActiveCategoryHandler = (newActiveCategory: string) => {
    dispatch(changeActiveCategory({ newActiveCategory }));
  };
  return (
    <Nav
      variant="tabs"
      className={`ps ${styles['portal-group-nav-wrapper']}`}
      defaultActiveKey={portalState.data.activeCategory}
      navbarScroll
    >
      {groups.map((group, index) => (
        <Nav.Item key={`category-group-${index}`}>
          <Nav.Link
            eventKey={group}
            onClick={() => onActiveCategoryHandler(group)}
          >
          {group === 'All' || group === 'Guest' ? t(`categories.${group}`) : group}          
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
}

export default PortalGroupNav;
