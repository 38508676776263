import axios from '../../../utils/api/axiosInstance';

export async function fetchConfig() {
  try {
    const response = await axios.get('/auth/config');
    console.log('Response: ', response);
    return response.data;
  } catch (error) {
    console.log('Error: ', error);
    throw error;
  }
}
