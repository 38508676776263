import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imageNotFound from '../../../assets/no_logo_found.png';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { toggleDrawer } from '../../app/store';
// import ImageNotFound from '../../general/components/ImageNotFound';
// import imageNotFound from '../../../assets/no_image_found.png';
import { addActivePortal, Portals } from '../../portal/store';

// enum ALLOWED_SIZES {
//   small = 'small',
//   medium = 'medium',
//   large = 'large',
//   xlarge = 'xlarge',
// }

const PORTAL_WIDTH_SIZES = {
  small: '12rem',
  medium: '18rem',
  large: '21rem',
  xlarge: '25rem',
};

const PORTAL_PADDING_SIZES = {
  small: '0.25rem',
  medium: '0.75rem',
  large: '1.25rem',
  xlarge: '1.35rem',
};

function Widget({ portal }: { portal: Portals }) {
  const settingsState = useAppStoreSelector((state) => state.settings.data);
  const dispatch = useAppStoreDispatch();
  // const onPortalAccess = (id: number) => {
  //   dispatch(addActivePortal({ id }));
  // };
  return (
    <Card
      className="ps"
      style={{
        width:
          PORTAL_WIDTH_SIZES[
            // settingsState!.generalSetup.overralSettings.widgets.size
            settingsState!.sizes.widgets
          ],
        overflow: 'hidden',
      }}
      key={portal.portalID}
    >
      <Link
        to={`/portal/${portal.portalID}`}
        style={{
          textDecoration: 'none',
          height: '100%',
          // backgroundColor: 'red'
        }}
        // className="btn btn-outline-primary"
        target={
          settingsState?.isNewPageChecked ? portal.portalID.toString() : ''
        }
        onClick={() => dispatch(toggleDrawer(false))}
      >
        {portal.portalWidgetImageValue ? (
          <Card.Img
            variant="top"
            src={
              portal.portalWidgetImageValue
                ? portal.portalWidgetImageValue
                : settingsState?.noImage
                ? settingsState.noImage
                : imageNotFound
            }
          />
        ) : settingsState?.noImage ? (
          <Card.Img
            style={{ minHeight: '15rem', objectFit: 'contain' }}
            variant="top"
            src={settingsState.noImage}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '12.5rem',
              padding: '1rem',
              backgroundColor: '#f7f7f7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Card.Img style={{ width: '50%' }} src={imageNotFound} />
            {/* <ImageNotFound /> */}
          </div>
        )}
        <hr style={{ margin: '0' }} />
        <Card.Body
          style={{
            padding:
              PORTAL_PADDING_SIZES[
                // settingsState!.generalSetup.overralSettings.widgets.size
                settingsState!.sizes.widgets
              ],
          }}
        >
          <Card.Title>{portal.portalWidgetHeadline}</Card.Title>
          <Card.Text>{portal.portalWidgetText}</Card.Text>
        </Card.Body>
        {/* <Card.Header */}
        {/*   className="text-primary" */}
        {/*   style={{ */}
        {/*     textAlign: 'right', */}
        {/*     // fontSize: */}
        {/*     //   PORTAL_FONT_SIZES[ */}
        {/*     //     settingsState!.generalSetup.overralSettings.widgets.size */}
        {/*     //   ], */}
        {/*     borderBottom: 0, */}
        {/*   }} */}
        {/* > */}
        {/* <Link */}
        {/*   to={`/portal/${portal.portalID}`} */}
        {/*   className="btn btn-outline-primary" */}
        {/*   target={ */}
        {/*     settingsState?.isNewPageChecked ? portal.portalID.toString() : '' */}
        {/*   } */}
        {/*   onClick={() => dispatch(toggleDrawer(false))} */}
        {/* > */}
        {/*   Access Portal */}
        {/* </Link> */}
        {/* </Card.Header> */}
      </Link>
    </Card>
  );
}

export default Widget;
