import { Helmet } from 'react-helmet';
import { useAppStoreSelector } from '../store';

function usePageTitle(prefix?: string) {
  const settings = useAppStoreSelector((state) => state.settings);
  let title = 'Portal Station';
  if (settings.data?.appHead?.pageTitle) {
    title = settings.data.appHead.pageTitle;
  }

  if (prefix) {
    prefix = prefix + ' | ';
  }

  let metaDescritpion = 'Elevate your data handling with ADIB Portal by Conigon: A user-friendly portal for easy data integration, modern product catalogs, and digital infrastructure enhancement without extra programming.';
  if (settings.data?.appHead?.metaDescription) {
    metaDescritpion = settings.data.appHead.metaDescription;
  }


  // const fallbackTitle = 'Portal Station';
  const titleElement = (
    <Helmet>
      <title>{`${prefix ? prefix : ''}${title}`}</title>
      <meta name="description" content={metaDescritpion}/>
      <link rel="shortcut icon" href={settings.data?.appHead?.favicon} type="image/png"/>
    </Helmet>
  );

  return [titleElement];
}

export default usePageTitle;
