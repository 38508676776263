import { createRoot } from 'react-dom/client';
import Root from './Root';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import StoreProvider from './store';
import TranslationProvider from './utils/translations/Config';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Toast from './features/general/components/Toast';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/css/bootstrap.css';
// import './styles/bootstrap.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
import './styles/base.scss';
// import { ThemeProvider } from 'react-bootstrap';

library.add(far, fas);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <StoreProvider>
    <TranslationProvider>
      <Toast />
      <Root />
    </TranslationProvider>
  </StoreProvider>
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
