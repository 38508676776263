import axios from '../../../utils/api/axiosInstance';

export async function auth<T>(path: string, body: T) {
  try {
    const response = await axios.post<{ token: string }>(path, body);
    // attachTokenInterceptor(response.data.token);
    return response.data;
  } catch (error) {
    const errorObj = error as any;
    const message = errorObj.response.data.message as string;
    console.log('Axios: ', error);
    throw new Error(message);
  }
}

// export async function register<T>(path: string, body: T) {
//     try {
//         const response = await axios.post<{token: string}>(path, body);
//         attachTockenInterceptor(response.data.token);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// }
