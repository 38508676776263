import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchConfig } from '../api/configAPI';

export const CONFIG_SLICE_NAME = 'config';

export const fetchConfigThunk = createAsyncThunk(
  'config/fetchConfig',
  async () => {
    try {
      const response = await fetchConfig();
      return response;
    } catch (error) {
      console.log('Error: ', error);
      throw error;
    }
  }
);

interface SliceDataState {
  loading: boolean;
  data: {
    authConfig: {
      jwtPublicKey: string;
      refreshPath: string;
      requestFieldName: {
        username: string;
        password: string;
      };
      methods: {
        local: {
          loginEnabled: boolean;
          path: string;
          registerPath: string;
        };
        oidc: {
          URL: string;
          icon: string;
          name: string;
        };
      };
    };
  } | null;
}

const initialState: SliceDataState = {
  data: null,
  loading: false,
};

export const configSlice = createSlice({
  name: CONFIG_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigThunk.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(fetchConfigThunk.fulfilled, (state, action) => {
      state.data = { authConfig: action.payload };
      state.loading = false;
    });
    builder.addCase(fetchConfigThunk.rejected, (state, action) => {
      // state.loading = true;
      console.log(state, action);
    });
  },
});

export default configSlice.reducer;
