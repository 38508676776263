import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { fetchPortalStationConfig } from '../../app/api/portalAPI';

export const fetchSettingsThunk = createAsyncThunk(
    'settings/fetchSettings',
    async (thunkAPI) => {
        try {
            const response = await fetchPortalStationConfig();
            console.log('Settings: ', response);
            return response;
        } catch (error) {
            console.log('Error: ', error);
            throw new Error((error as AxiosError).response?.statusText);
        }
    }
);

export const SETTINGS_SLICE_NAME = 'settings';

export type Settings = {
    appHead: {
        favicon: string;
        pageTitle: string;
        metaDescription: string;
    };
    logo: string;
    noImage: string;
    isNewPageChecked: boolean;
    sizes: {
        navbar: string;
        drawer: string;
        widgets: 'medium' | 'small' | 'large' | 'xlarge';
    };
    loginSetup: {
        type: 'classical' | 'centered';
        image: 'left' | 'center' | 'right' | 'none' | 'background';
        loginImage: string | null;
        authFunctions: ['login', 'register', 'forgotPassword', 'keyclock'];
    };
    backendVersion: string;
};

interface SettingsDataState {
    data?: Settings;
}

const initialState: SettingsDataState = {
    data: undefined,
};

export const settingsSlice = createSlice({
    name: SETTINGS_SLICE_NAME,
    initialState,
    reducers: {
        addSettings(state, action) {
            console.log('Settings: ', action.payload);
            const settings = action.payload as Settings;
            settings.isNewPageChecked = false;
            return {
                ...state,
                data: settings,
            };
        },
        loadIsNewPageSetting(state) {
            if (localStorage.getItem('newPageSetting')) {
                const isChecked = JSON.parse(localStorage.getItem('newPageSetting')!);
                if (state.data) {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            isNewPageChecked: isChecked,
                        },
                    };
                }
            }
            return state;
        },
        toggleNewPageSwitch(state, action) {
            const checked = action.payload as boolean;
            localStorage.setItem('newPageSetting', JSON.stringify(checked));
            if (state.data) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        isNewPageChecked: checked,
                    },
                };
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchSettingsThunk.fulfilled,
            settingsSlice.caseReducers.addSettings
        );
    },
});

export const { toggleNewPageSwitch, loadIsNewPageSetting } =
    settingsSlice.actions;

export default settingsSlice.reducer;
