import { useEffect, useState } from 'react';

export function useCookie(cookieName: string) {
  const [cookie, setCookie] = useState('');
  useEffect(() => {
    setCookie(
      document.cookie
        .match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')
        ?.pop() || ''
    );
  }, [document.cookie]);

  const eraseCookie = () => {
    document.cookie = cookieName + '=; Max-Age=-99999999;';
  };
  return [cookie, eraseCookie] as const;
}
