import { redirect, RouteObject, useNavigate } from 'react-router-dom';
import User from '../../auth/models/user';
import NotFound404 from '../../general/components/NotFound404';
import App from '../App';
import portalRoutes from '../../portal/routes';
import dashboardRoutes from '../../dashboard/routes';
import adminRoutes from '../../admin/routes';

function appRoutes() {
  return [
    {
      path: '/',
      element: <App />,
      // loader: () => {
      //   const token = User.loadUserToken();
      //   if (!token) {
      //     return redirect('/auth');
      //   }
      //   return null;
      // },
      children: [...portalRoutes, ...dashboardRoutes, ...adminRoutes],
    },
  ] as RouteObject[];
}

export default appRoutes;
