import { useAppStoreDispatch } from '../../../store';
import { triggerMessage } from '../store/toast';

export function useToast() {
  const dispatch = useAppStoreDispatch();
  const triggerMessageHandler = (message: string, isError: boolean) => {
    dispatch(triggerMessage({ message, isError }));
  };

  return [triggerMessageHandler];
}
