import { useEffect, useState } from 'react';
import Loading from './features/general/components/Loading';
// import Error from './features/general/Error';
import { useAppStoreDispatch, useAppStoreSelector } from './store';
import { fetchConfigThunk } from './features/config/store';
import User from './features/auth/models/user';
import {
  addUserData,
  refreshTokenThunk,
} from './features/auth/store/userSlice';
import AppContent from './routes';
import { useTranslation } from 'react-i18next';
import { Events } from './utils/constants';
// import config from './config.json';
import Color from './utils/colors/colorConfig';
import { useToast } from './features/general/components/Toast.hook';
import {
  fetchSettingsThunk,
  loadIsNewPageSetting,
} from './features/settings/store';
// import Dashboard from './features/dashboard/Dashboard';
import { fetchGuestPortalsThunk } from './features/portal/store';
import { fetchCategoriesThunk } from './features/category/store';
import { useCookie } from './hooks/useCookie';
// import { attachTokenInterceptor } from './utils/api/axiosInstance';
import usePageTitle from './hooks/usePageTitle';

function Root() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppStoreDispatch();
  const { i18n } = useTranslation();
  const [triggerMessageHandler] = useToast();
  console.log('Root rendering');
  const [token, eraseTokenCookie] = useCookie('token');
  const [expirationDate, eraseExpirationDateCookie] = useCookie('expires');
  const config = useAppStoreSelector((state) => state.config);
  const [title] = usePageTitle();

  useEffect(() => {
    // window.onbeforeunload = function () {
    //   sessionStorage.clear();
    // };

    async function loadContentOnRenderHandler() {
      setLoading(true);
      try {
        await dispatch(fetchGuestPortalsThunk()).unwrap();
        await dispatch(fetchSettingsThunk()).unwrap();
        await dispatch(fetchConfigThunk()).unwrap();
        dispatch(loadIsNewPageSetting());
        const token = User.loadUserToken();
        if (token) {
          await dispatch(refreshTokenThunk(token)).unwrap();
        }
      } catch (error) {
        User.expireUserSession(triggerMessageHandler);
      } finally {
        setLoading(false);
      }
    }

    loadContentOnRenderHandler();
    window.addEventListener(
      Events.LANGUAGE_CHANGED_EVENT,
      (customEvent: Event) => {
        const { detail } = customEvent as CustomEvent;
        i18n.changeLanguage(detail as string);
      }
    );

    // console.log('Token: ', token);
    // loadColorConfig();
  }, []);

  // useEffect(() => {
  //   // console.log('Token: ', token);
  //   const publicKey = config.data?.authConfig.jwtPublicKey;
  //   if (token && expirationDate && publicKey) {
  //     console.log('Token: ', token);
  //     dispatch(addUserData({ token, publicKey }));
  //     // User.persistUser(token);
  //     eraseTokenCookie();
  //     eraseExpirationDateCookie();
  //     attachTokenInterceptor(token);
  //   }
  // }, [token, config.data?.authConfig.jwtPublicKey]);

  // useEffect(() => {
  //   console.log('Is guest: ', isGuest);
  // }, [isGuest]);

  // useEffect(() => {
  //   loadColorConfig();
  // }, [postSettingsState?.colors]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {title}
      <AppContent />
    </>
  );
}

export default Root;
