import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import styles from './GuestModeLabel.module.scss';

function GuestModeLabel() {
  return (
    <div className={styles['guest-mode-label-wrapper']}>
      <Trans i18nKey="dashboard.guestModeLabel">
        {/* The Link component here corresponds to <0></0> in the translation */}
        <Link to="/auth">here</Link>
      </Trans>
    </div>
  );
}

export default GuestModeLabel;
