import usePageTitle from '../../hooks/usePageTitle';
import { useAppStoreSelector } from '../../store';
import { useAuthGuard } from './Auth.hook';
import CenterAuthStructure from './components/structures/CenterAuthStructure';
import DoubleSideAuthStructure from './components/structures/DoubleSideAuthStructure';

function Auth() {
  useAuthGuard();
  const [title] = usePageTitle('Auth');
  const settingsState = useAppStoreSelector((state) => state.settings);
  if (settingsState.data) {
    // const currentLoginSetup = settingsState.data.generalSetup.loginSetup[0];
    const currentLoginSetup = settingsState.data.loginSetup;

    switch (currentLoginSetup.type) {
      case 'classical':
        if (currentLoginSetup.image === 'left') {
          return (
            <>
              {title}
              <DoubleSideAuthStructure
                isRight
                image={currentLoginSetup.loginImage}
              />
            </>
          );
        } else {
          return (
            <>
              {title}
              <DoubleSideAuthStructure
                isRight={false}
                image={currentLoginSetup.loginImage}
              />
            </>
          );
        }
      case 'centered':
        return (
          <>
            {title}
            <CenterAuthStructure
              imageUrl={currentLoginSetup.loginImage}
              logoBrand={settingsState.data.logo}
            />
          </>
        );
      default:
        return (
          <>
            {title}
            <DoubleSideAuthStructure image={currentLoginSetup.loginImage} />
          </>
        );
    }
  }
  return (
    <>
      {title}
      <DoubleSideAuthStructure image={null} />
    </>
  );
}

export default Auth;
