// import { Suspense } from 'react';
import { useEffect, useState } from 'react';
import AppBar from '../app/components/AppBar';
import { useAppStoreDispatch, useAppStoreSelector } from '../../store';
// import { fetchPortalsThunk, selectIsGuestMode } from '../portal/store';
import { Outlet, useNavigate } from 'react-router-dom';
import Loading from '../general/components/Loading';
import { useAuthGuard } from '../auth/Auth.hook';
import { fetchCategoriesThunk } from '../category/store';
import Footer from './components/Footer';
// import { Spinner } from 'react-bootstrap';

export default function App() {
  useAuthGuard();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppStoreDispatch();
  // const navigate = useNavigate();
  const userState = useAppStoreSelector((state) => state.user);
  const settingsState = useAppStoreSelector((state) => state.settings);
  // const isGuest = useAppStoreSelector(selectIsGuestMode);
  console.log('App rendering');

  useEffect(() => {
    const fetchPortalsOnComponentLoad = async () => {
      setLoading(true);
      try {
        await dispatch(fetchCategoriesThunk()).unwrap();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (userState.data.token) {
      fetchPortalsOnComponentLoad();
    }
  }, []);

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar setAppLoading={setLoading} />
      <main
        className="bg-body"
        style={{
          // overflow: 'scroll',
          overflow: 'auto',
          width: '100%',
          backgroundColor: 'red !important',
          position: 'relative',
          flex: 1,
          // paddingTop: settingsState.data?.sizes.navbar.heigth
          //   ? settingsState.data.sizes.navbar.heigth
          //   : '3.5rem',
          paddingTop: settingsState.data?.sizes.navbar
            ? settingsState.data.sizes.navbar
            : '3.5rem',
        }}
      >
        {/* <Suspense fallback={<Loading />}> */}
        {loading ? <Loading /> : <Outlet />}
        {/* </Suspense> */}
      </main>
      <Footer />
    </div>
  );
}
