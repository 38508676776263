import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { findIcon } from '../../../utils/iconFinder';

function DrawerItemImage({ portalWidgetImage }: { portalWidgetImage: string }) {
  return (
    <div
      style={{
        width: '100%',
        borderRadius: '14%',
        border: 'solid 1px black',
        overflow: 'hidden',
      }}
    >
      <img style={{ width: '100%' }} src={portalWidgetImage} />
    </div>
  );
}

function DrawerItem({
  portalName,
  portalLink,
  setPortalDrawer,
  portalWidgetImage,
}: {
  portalName: string;
  portalLink: string;
  setPortalDrawer: () => void;
  portalWidgetImage: string;
}) {
  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{portalName}</Tooltip>}>
      <Nav.Item style={{ width: '100%' }}>
        <NavLink
          to={portalLink}
          className={({ isActive }) =>
            `nav-link ${isActive && 'active'} py-3 border-bottom rounded-0`
          }
          style={{ lineHeight: 0, margin: 0 }}
          onClick={setPortalDrawer}
        >
          {portalWidgetImage ? (
            <DrawerItemImage portalWidgetImage={portalWidgetImage} />
          ) : (
            <FontAwesomeIcon icon={findIcon('far', 'window-maximize')} />
          )}
        </NavLink>
      </Nav.Item>
    </OverlayTrigger>
  );
}

export default DrawerItem;
