import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { fetchCategories } from '../api/categoryAPI';

export const fetchCategoriesThunk = createAsyncThunk(
  'categories',
  async (_thunkAPI) => {
    try {
      const response = await fetchCategories();
      console.log('Categories: ', response);
      return response;
    } catch (error) {
      console.log('Error: ', error);
      throw new Error((error as AxiosError).response?.statusText);
    }
  }
);

export const CATEGORY_SLICE_NAME = 'category';

interface SliceDataState {
  data: {
    categories: string[];
  };
}

const initialState: SliceDataState = {
  data: {
    categories: [],
  },
};

export const categorySlice = createSlice({
  name: CATEGORY_SLICE_NAME,
  initialState,
  reducers: {
    addCategories(state, action) {
      console.log(action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          categories: (action.payload as { name: string }[]).map(
            (category) => category.name
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCategoriesThunk.fulfilled,
      categorySlice.caseReducers.addCategories
    );
  },
});

export default categorySlice.reducer;
