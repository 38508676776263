import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { cleanMessage } from '../store/toast';

const ToastEl = () => {
  const toastState = useAppStoreSelector((state) => state.toast);
  const dispatch = useAppStoreDispatch();
  const { isError, isShow, message } = toastState.data;

  const onToastCloseHandler = () => {
    dispatch(cleanMessage());
  };
  return (
    <ToastContainer
      className="position-absolute p-3"
      position="top-end"
      style={{ zIndex: 100 }}
    >
      <Toast
        onClose={onToastCloseHandler}
        show={isShow}
        delay={3000}
        autohide
        bg={isError ? 'danger' : 'success'}
      >
        <Toast.Header closeButton={false}>
          <strong>{isError ? 'Error' : 'Success'}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastEl;
