import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function RememberMe({
  rememberMe,
  onRememberMeCheckChangeHandler,
}: {
  rememberMe: boolean;
  onRememberMeCheckChangeHandler: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Form.Group className="mb-3" controlId="formBasicCheckbox">
      <Form.Check
        type="checkbox"
        label={t('auth.rememberMe')}
        checked={rememberMe}
        onChange={onRememberMeCheckChangeHandler}
      />
    </Form.Group>
  );
}

export default RememberMe;
